import React from 'react';
import survivorLogo from './assets/Survivor_45_logo.webp';
import Contestants from './Contestants';
import DropdownAddy from './DropdownAddy';
import DropdownAdriano from './DropdownAdriano';
import DropdownCasey from './DropdownCasey';
import DropdownChristina from './DropdownChristina';
import survivorgif from './assets/survivorgif.gif';
import tally from './assets/tally.gif';

const Home = () => {
  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-around',
          marginRight: 100,
          marginLeft: 100,
        }}
      >
        <img src={survivorgif} style={{ borderRadius: '20%' }} alt="my-gif" />
        <img src={survivorLogo} width={450} height={250} />
        <img src={tally} style={{ borderRadius: '20%' }} alt="my-gif" />
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
        }}
      >
        <DropdownAddy />
        <DropdownChristina />
        <DropdownAdriano />
        <DropdownCasey />
      </div>

      <Contestants />
    </div>
  );
};

export default Home;
