// Import the functions you need from the SDKs you need
// import { initializeApp } from 'firebase/app';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { getFirestore } from 'firebase/firestore';
// Your web app's Firebase configuration

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyCnK5dkeKdfZVTC99kEy95zeD5TbGnBL44',
  authDomain: 'survivor45-fb69d.firebaseapp.com',
  projectId: 'survivor45-fb69d',
  storageBucket: 'survivor45-fb69d.appspot.com',
  messagingSenderId: '382091331756',
  appId: '1:382091331756:web:d600ab71cd375394657709',
};

// Initialize Firebase
let app;
if (firebase.apps.length === 0) {
  app = firebase.initializeApp(firebaseConfig);
} else {
  app = firebase.app();
}

const auth = firebase.auth();

const db = getFirestore();

export { auth, db };
