import React from 'react';
import austin from './assets/austinlicoon.jpg';
import brandon from './assets/brandondonlon.jpg';
import brando from './assets/brandonmeyer.jpg';
import bruce from './assets/bruceperreault.jpg';
import dee from './assets/deevalladares.jpg';
import drew from './assets/drewbasile.jpg';
import emily from './assets/emilyflippen.jpg';
import hannah from './assets/hannahrose.jpg';
import jake from './assets/jakeokane.jpg';
import jana from './assets/jananikrishnan-jha.jpg';
import julie from './assets/juliealley.jpg';
import kaleb from './assets/kalebgebrewold.jpg';
import katurah from './assets/katurahtopps.jpg';
import kellie from './assets/kellienalbandian.jpg';
import kendra from './assets/kendramcquarrie.jpg';
import nicholas from './assets/nicholasalsup.jpg';
import sabiyah from './assets/sabiyahbroderick.jpg';
import sean from './assets/seanedwards.jpg';

const Contestants = () => {
  const firstLine = [
    {
      name: 'Hannah',
      picture: hannah,
      status: false,
    },
    {
      name: 'Brandon',
      picture: brandon,
      status: true,
    },
    {
      name: 'Emily',
      picture: emily,
      status: true,
    },
    {
      name: 'Kaleb',
      picture: kaleb,
      status: true,
    },

    {
      name: 'Sabiyah',
      picture: sabiyah,
      status: true,
    },

    {
      name: 'Sean',
      picture: sean,
      status: true,
    },
  ];
  const secondLine = [
    {
      name: 'Austin',
      picture: austin,
      status: true,
    },
    {
      name: 'Julie',
      picture: julie,
      status: true,
    },
    {
      name: 'Drew',
      picture: drew,
      status: true,
    },

    {
      name: 'J',
      picture: jana,
      status: true,
    },

    {
      name: 'Dee',
      picture: dee,
      status: true,
    },
    {
      name: 'Nicholas',
      picture: nicholas,
      status: true,
    },
  ];
  const thirdLine = [
    {
      name: 'Brando',
      picture: brando,
      status: true,
    },
    {
      name: 'Kendra',
      picture: kendra,
      status: true,
    },
    {
      name: 'Kellie',
      picture: kellie,
      status: true,
    },
    {
      name: 'Jake',
      picture: jake,
      status: true,
    },
    {
      name: 'Bruce',
      picture: bruce,
      status: true,
    },

    {
      name: 'Katurah',
      picture: katurah,
      status: true,
    },
  ];

  const firstLineTotal = firstLine.map((item, index) => (
    <div style={{ width: '50%' }}>
      <img
        src={item.picture}
        alt=""
        width={200}
        height={240}
        style={{
          borderRadius: '20%',
          border: '10px solid yellow',
        }}
      />
      <h2>{item.name}</h2>
    </div>
  ));
  const secondLineTotal = secondLine.map((item, index) => (
    <div style={{ width: '50%' }}>
      <img
        src={item.picture}
        alt=""
        width={200}
        height={240}
        style={{
          borderRadius: '20%',
          border: '10px solid blue',
        }}
      />
      <h2>{item.name}</h2>
    </div>
  ));
  const thirdLineTotal = thirdLine.map((item, index) => (
    <div style={{ width: '50%' }}>
      <img
        src={item.picture}
        alt=""
        width={200}
        height={240}
        style={{
          borderRadius: '20%',
          border: '10px solid red',
        }}
      />
      <h2>{item.name}</h2>
    </div>
  ));
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        // width: '75%',
        justifyContent: 'space-around',
      }}
    >
      <h1>Contestants</h1>
      <h2>Lulu Tribe</h2>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        {firstLineTotal}
      </div>
      <h2>Reba Tribe</h2>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        {secondLineTotal}
      </div>
      <h2>Belo Tribe</h2>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        {thirdLineTotal}
      </div>
    </div>
  );
};

export default Contestants;
