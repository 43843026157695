import React, { useState } from 'react';
import Select from 'react-dropdown-select';
import Button from '@mui/material/Button';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { db } from './firebase';
import christina from './assets/christina.PNG';

const DropdownChristina = () => {
  const options = [
    {
      value: 1,
      label: 'Julie',
    },
    {
      value: 2,
      label: 'Nicholas',
    },
    {
      value: 3,
      label: 'Drew',
    },
    {
      value: 4,
      label: 'Sabiyah',
    },
    {
      value: 5,
      label: 'Austin',
    },
    {
      value: 6,
      label: 'BrandoN',
    },
    {
      value: 7,
      label: 'Sean',
    },
    {
      value: 8,
      label: 'Emily',
    },
    {
      value: 9,
      label: 'Kaleb',
    },
    {
      value: 10,
      label: 'Janani',
    },
    {
      value: 11,
      label: 'BrandO',
    },
    {
      value: 12,
      label: 'Kendra',
    },
    {
      value: 13,
      label: 'Kellie',
    },
    {
      value: 14,
      label: 'Jake',
    },
    {
      value: 15,
      label: 'Bruce',
    },
    {
      value: 16,
      label: 'Hannah',
    },
    {
      value: 17,
      label: 'Katurah',
    },
    {
      value: 18,
      label: 'Dee',
    },
  ];
  const [firstPlace, setFirstPlace] = useState('');
  const [secondPlace, setSecondPlace] = useState('');
  const [thirdPlace, setThirdPlace] = useState('');
  const [fourthPlace, setFourthPlace] = useState('');
  const [fifthPlace, setFifthPlace] = useState('');

  const handleSave = async () => {
    if (
      !firstPlace ||
      !secondPlace ||
      !thirdPlace ||
      !fourthPlace ||
      !fifthPlace
    ) {
      alert(
        'I LITERALLY TOLD YOU GUYS THAT YOU MUST MAKE A SLECTION FOR EVERYTHING BEFORE SAVING, GEEZ'
      );
      return;
    }

    // Add a new document in collection "cities"
    await setDoc(doc(db, 'choices', 'christina'), {
      firstPlace: firstPlace,
      secondPlace: secondPlace,
      thirdPlace: thirdPlace,
      fourthPlace: fourthPlace,
      fifthPlace: fifthPlace,
    });
    alert('Goodluck Christina! See you at brunch.');
  };

  const getData = async () => {
    const docRef = doc(db, 'choices', 'christina');
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      console.log('Document data:', docSnap.data());
      const choiceData = docSnap.data();
      console.log('typeof: ', typeof choiceData.firstPlace);
      setFirstPlace(choiceData.firstPlace);
      setSecondPlace(choiceData.secondPlace);
      setThirdPlace(choiceData.thirdPlace);
      setFourthPlace(choiceData.fourthPlace);
      setFifthPlace(choiceData.fifthPlace);
    } else {
      // docSnap.data() will be undefined in this case
      console.log('No such document!');
    }
  };

  useState(() => {
    getData();
    console.log('first: ', firstPlace);
  }, []);

  return (
    <div style={{ width: '300px', padding: '50px' }}>
      <img
        src={christina}
        width={200}
        height={200}
        style={{
          borderRadius: '50%',
          border: '3px solid blue',
        }}
      />
      <h1>Christina</h1>

      <Select
        options={options}
        placeholder={firstPlace || 'First Place'}
        onChange={(values) => setFirstPlace(values[0].label)}
      />

      <Select
        options={options}
        placeholder={secondPlace || 'Second Place'}
        onChange={(values) => setSecondPlace(values[0].label)}
      />

      <Select
        options={options}
        placeholder={thirdPlace || 'Third Place'}
        onChange={(values) => setThirdPlace(values[0].label)}
      />

      <Select
        options={options}
        placeholder={fourthPlace || 'Fourth Place'}
        onChange={(values) => setFourthPlace(values[0].label)}
      />

      <Select
        options={options}
        placeholder={fifthPlace || 'Fifth Place'}
        onChange={(values) => setFifthPlace(values[0].label)}
      />

      <div style={{ padding: 20 }}></div>
      <Button variant="contained" fullWidth={true} onClick={() => handleSave()}>
        Save
      </Button>
      <div>
        <h3>Points Left</h3>
        <h1>TBD</h1>
      </div>
    </div>
  );
};

export default DropdownChristina;
