import './App.css';
import Home from './Home';

function App() {
  return (
    <div className="App">
      <div>
        <h1>2023 Survivor 45 Tournament</h1>
        <Home />
      </div>
    </div>
  );
}

export default App;
